import $ from "jquery";

/**
 * Adds a .is-current-page class to buttons.
 * 
 * Used to highlight the current page
 * in Practice Areas Pagination reusable block.
 */

$(function () {
  $.each($('.wp-block-button__link'), function () {
    let $t = $(this);
    let href = $t.attr('href');

    if (href == window.location.href) {
      $t.parent('.wp-block-button').addClass('is-current-page');
    }
  })
})
