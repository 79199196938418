/**
 * If we import main.scss here, remove it from the entry array in ideasonpurpose.config.js
 */

// require('../sass/main.scss')
// require('../js/modules/share.js');
// require('../js/modules/menu.js');

require('./modules/vw-fix.js');
require('./modules/cookie-notice.js');
require('./modules/splide.js');
require('./modules/site-header.js');
require('./modules/search.js');
require('./modules/is-current-page.js');

// Tracking
require('./lib/iop-scroll-depth.js');
require('./modules/event-tracking.js');
