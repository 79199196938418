import Splide from "@splidejs/splide";

// Homepage Hero
if (document.getElementById("splide")) {
  new Splide("#splide", {
    arrows: false,
    autoplay: true,
    type: "loop",
    perPage: 1,
    interval: 5000,
  }).mount();
}

/**
 * Enable "Accoldates" slider blocks
 *
 * These need to be instantiated from a loop in case more than one
 * block was added to a page.
 */
const splides = document.getElementsByClassName("slider");
[...splides].forEach((el) => {
  const slideCount = el.getElementsByClassName("splide__slide").length;
  let slidesPerPage = el.dataset.perpage || 1;
  const gap = parseInt(el.dataset.gap) || 66;
  slidesPerPage = slideCount > 1 ? slidesPerPage : 1;

  let splide = new Splide(el, {
    pagination: false,
    type: "loop",
    gap: gap,
    interval: 5000,

    perPage: slidesPerPage,
    perMove: slidesPerPage,

    breakpoints: {
      768: {
        perPage: 1, // Always 1 perPage below 768px
        perMove: 1,
      },
    },
  });

  /**
   * Handle Enough/Not Enough Slides
   * https://splidejs.com/guides/overflow/#observing-overflow
   */
  splide.on('overflow', function (isOverflow) {
    // Reset the carousel position
    splide.go(0);

    splide.options = {
      autoplay: isOverflow,
      arrows: isOverflow,
      drag: isOverflow,
      clones: isOverflow ? undefined : 0,
    };
  });

  splide.mount();
});
