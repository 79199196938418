/**
 * Fixes annoying 100vw overflowing and causing x scrollbar
 * 
 * Usage:
 * width: var(--vw, 100vw);
 */

const root = document.querySelector(':root');

function setVw() {
  let vw = document.documentElement.clientWidth;
  root.style.setProperty('--vw', `${vw}px`);
  root.style.setProperty('--half-vw-negative', `${vw / -2}px`);
}

setVw();
window.addEventListener('resize', setVw);
