import $ from "jquery";

const $menu = $(".header__menu");
const $searchToggle = $(".js-search-toggle");
const $searchForm = $(".header__search");
const activeClass = "search-form--is-active";

let mq = window.matchMedia("(min-width: 1024px)");

$searchToggle.on("click", function (e) {
  e.preventDefault();

  var $t = $(this);
  var query = $searchForm.find('[name="s"]').val();

  /**
   * Desktop functionality
   */
  if (mq.matches) {
    if ($menu.is("." + activeClass) && $t.is('[type="submit"]')) {
      // Perform search only if a query is present
      if (query.length > 0) $searchForm.submit();
      return false;
    }

    /**
     * Toggle the form state
     *
     * Note: Both the search button (when opening)
     * and X button (when closing) can toggle the form
     */
    $menu.toggleClass(activeClass);

    if ($menu.is("." + activeClass)) {
      /**
       * This timeout prevents the search input 'jump'
       * when the expand animation begins
       */
      setTimeout(function () {
        $menu.find("input").trigger('focus');
      }, 250);
    }
  } else {
    /**
     * Below desktop functionality
     * Perform search only if a query is present
     */
    if (query.length > 0) $searchForm.submit();
  }
});
