/**
 * Umami Custom Events
 */

import $ from "jquery";

function trackEvent(label, action, type) {
  /* DEBUG */
  console.info("Custom Event Sent", {
    type: type ? type : 'click',
    action: action,
    label: label,
    url: window.location.pathname
  });

  let event_data = {
    type: type ? type : 'click',
    action: action,
    label: label,
    url: window.location.pathname
  };

  if (typeof gtag !== 'undefined') {
    gtag('event', label, {
      'event_category': type ? type : 'click',
      'event_label': action,
      'non_interaction': true
    });
  }

  if (window.umami && umami.track) {
    umami.track(label, event_data);
  }
}

$('.js-toggle-menu').on('click', function () {
  let action = $('body').is('.menu-open') ? 'Hamburger - Open' : 'Hamburger - Close';
  trackEvent('Hamburger Menu', action);
});

$('.js-search-toggle').on('click', function () {
  let action = $('.header__menu').is('.search-form--is-active') ? 'Search Form - Open' : 'Search Form - Close';
  trackEvent('Search Form', action);
});

$('a[href^="mailto"]').on('click', function () {
  let action = $(this).attr('href').replace('mailto:', '');
  trackEvent('Email', action); // action = email address
});

$('a[href$=".vcf"]').on('click', function () {
  let parts = $(this).attr('href').split('/');
  let action = parts[parts.length - 1].replace('.vcf', '').replaceAll('-', ' ');
  trackEvent('vCard', action); // action = vCard filename
});

$('a[href^="tel:"]').on('click', function () {
  let action = $(this).attr('href').replace('tel:', '');
  trackEvent('Telephone', action); // action = tel number
});

$('.menu-main__menu a').on('click', function () {
  let title = $(this).text().trim();
  let action = $(this).attr('href');
  trackEvent('Main Menu  - ' + title, action); // action = tel number
});

$('.home .cards a').on('click', function () {
  let title = $(this).find('.card__name').text().trim();
  let action = $(this).attr('href');
  trackEvent('Homepage Cards - ' + title, action); // action = tel number
});
