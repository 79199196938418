import $ from "jquery";

const $body = $("body");
const $window = $(window);
const $menu = $(".header__menu");
const $menuButton = $(".js-toggle-menu");
const menuOpenClass = "menu-open";
const activeClass = "is-active";

let mq = window.matchMedia("(min-width: 1024px)");

/**
 * Toggle Menu
 *
 * Toggles the menuOpenClass on <body>
 *
 * Prevents <body> from being scrolled
 * while the hamburger menu is open
 */

function toggleMenu() {
  let wpMenuHeight = $("#wpadminbar").height() || 0;
  let top = $window.scrollTop() - wpMenuHeight;

  // Pin the body and prevent scrolling while the menu is open
  if (!$body.is("." + menuOpenClass)) {
    $body.css("top", -1 * top + "px").attr("data-scroll", top);
  }

  $body.toggleClass(menuOpenClass);

  // Scroll the body back to its initial position
  if (!$body.is("." + menuOpenClass)) {
    $('html').css('scroll-behavior', 'auto');
    $("body,html").scrollTop($("body").attr("data-scroll"));
    $('html').css('scroll-behavior', 'smooth');
  }
}

$menuButton.on("click", toggleMenu);

/**
 * Add arrow down buttons for submenus on mobile screen
 */
$.each($(".menu-main__menu .menu-item-has-children > a"), function () {
  $(
    '<button class="sub-menu-trigger"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12"><path fill="none" fill-rule="evenodd" stroke="#0E3A69" stroke-width="2" d="M1 1l9 9 9-9"/></svg><span class="a11y">Toggle Submenu</span></button>'
  ).insertAfter($(this));
});

/**
 * Sub menu trigger
 */
$(".sub-menu-trigger").on("click", function (e) {
  e.preventDefault();

  console.log('yo')

  let slideSpeed = mq.matches ? 1 : 250;

  $menu
    .find("." + activeClass)
    .not($(this))
    .removeClass(activeClass);
  $(".sub-menu").stop().slideUp(slideSpeed);

  $(this).toggleClass(activeClass).next().stop().slideToggle(slideSpeed);
});


$(window).on("resize", function () {
  $(".sub-menu").removeAttr("style");
});


$(window).on("scroll", function () {
  let scrollTop = $(window).scrollTop() ;
  $('.header').toggleClass('is--scrolled', scrollTop > 160);
});




