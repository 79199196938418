const Cookies = require("js-cookie");

const className = "cookie-notice--active";
const cookieName = "cookie-notice-consent";
const cookieTime = Math.floor(new Date().getTime() / 1000);

if (Cookies.get(cookieName) === undefined) {
  document.addEventListener("DOMContentLoaded", () => {
    const body = document.querySelector("body");
    body.classList.add(className);

    const cookieCloseHandler = () => {
      body.classList.remove(className);
      Cookies.set(cookieName, cookieTime, { expires: 30 });
    };

    document
      .querySelector(".js--cookie-notice-close")
      .addEventListener("click", cookieCloseHandler);
  });
}
